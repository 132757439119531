import React, {useMemo} from "react";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemButton, ListSubheader} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

const ReportsSideMenu = ({assetId, classes, tab}) => {
    const listItems = useMemo(
        () => [{
            "group": "Income",
            "items": [
                {id: 1, to: `/BaseRent/Details/${assetId}`, label: 'Base Rent', active: 'BaseRent'},
                {
                    id: 2,
                    to: `/TurnoverRent/Details/${assetId}`,
                    label: 'Turnover Rent',
                    active: 'TurnoverRent'
                },
                {
                    id: 3,
                    to: `/ServiceCharge/Details/${assetId}`,
                    label: 'Service Charge',
                    active: 'ServiceCharge'
                },
                {
                    id: 4,
                    to: `/OtherIncome/Details/${assetId}`,
                    label: 'Other Income',
                    active: 'OtherIncome'
                },
                {
                    id: 5,
                    to: `/CumulativeRent/Details/${assetId}`,
                    label: 'Cumulative Rent',
                    active: 'CumulativeRent'
                },
                {
                    id: 6,
                    to: `/NOI/Details/${assetId}`,
                    label: 'Net Operating Income',
                    active: 'NOI'
                }
            ]
        },
            {
                "group": "Expenses",
                "items": [
                    {id: 1, to: `/Opex/Details/${assetId}`, label: 'Opex', active: 'Opex'},
                    {id: 2, to: `/Capex/Details/${assetId}`, label: 'Capex', active: 'Capex'},
                    {
                        id: 3,
                        to: `/LeasingFee/Details/${assetId}`,
                        label: 'Leasing Fee',
                        active: 'LeasingFee'
                    },
                    {
                        id: 4,
                        to: `/FitOut/Details/${assetId}`,
                        label: 'Fit Out',
                        active: 'FitOut'
                    },
                    {
                        id: 5,
                        to: `/OtherExpenses/Details/${assetId}`,
                        label: 'Other Expenses',
                        active: 'OtherExpenses'
                    }
                ]
            }
        ],
        [assetId]
    );

    return <Paper elevation={3} sx={{
        overflowY: 'auto', height: {
            lg: 'calc(100vh - 280px)',
            xl: '100%',  // For medium and larger screens
        }
    }}>
        {listItems.map((item) => (
            <List
                subheader={
                    <ListSubheader variant="h6" sx={{borderRadius: 'inherit'}}>
                        {item.group}
                    </ListSubheader>
                }
                sx={{borderRadius: 'inherit'}}
            >
                {item.items.map((item) => (
                    <ListItem disablePadding key={item.id} sx={{borderRadius: 'inherit'}}>
                        <ListItemButton to={item.to} selected={tab === item.active}>
                            <ListItemText primary={item.label}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        ))}
    </Paper>;
};

export default ReportsSideMenu;