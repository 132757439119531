import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-around'
  },
  textRed: {
    color: 'red',
    fontWeight: 'bold'
  },
  textGreen: {
    color: 'green',
    fontWeight: 'bold'
  },
  fromText: {
    fontSize: '12px',
    color: '#ddd',
    padding: '0 5px'
  },
  iconRed: {
    color: 'red'
  },
  iconGreen: {
    color: 'green'
  },
  wrapHeading: {
    display: 'flex',
    alignItems: 'baseline'
  },
  wrapCard: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  boxAnnotations: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAnnotations: {
    border: '1px solid #ddd',
    padding: '5px',
    borderRadius: '4px',
    margin: '10px 0'
  },
  minWidth: {
    minWidth: 180
  },
  noBorder: {
    borderBottom: 'none !important',
    padding: theme.spacing(1)
  },
  cell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flexWrapper: {
    flex: 4,
    padding: '0 0 0 50px'
  },
  margin: {
    margin: '50px 0 0 0'
  },
  padding: {
    padding: '20px'
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

export default useStyles;
