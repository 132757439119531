import React, {useState} from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import {formatNumberToCurrency} from 'helpers/utils';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';

import useStyles from './styles';
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css"; // Mandatory CSS required by the grid
import 'ag-grid-enterprise';

const getColor = (value) => {
    if (value > 0) return '#3fab2c';
    if (value < 0) return '#c42929';
    return '#999';
};

const Loader = () => (
    <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress/>
    </Box>
);

const NoData = () => (
    <Typography variant="subtitle1" style={{textAlign: 'center'}}>
        No records to display.
    </Typography>
);

function currencyFormatter(params) {
    return formatNumberToCurrency(params.value, 'EUR', '-', 0);
}

const TableSummary = ({data, loading, loaded, tab}) => {
    const classes = useStyles();

    const renderFallback = () => (
        <TableCell colSpan="10" className={clsx(classes.cell, classes.minWidth, classes.noBorder)}>
            {loading && <Loader/>}
            {loaded && isEmpty(data) && <NoData/>}
        </TableCell>
    );

    const [colDefs, setColDefs] = useState([
        {
            field: 'title', headerName: '',
        },
    ])

    if (tab !== 'CumulativeRent') {
        colDefs.push({
            headerName: 'Total',
            cellStyle: {backgroundColor: 'lightgrey'},
            valueFormatter: (params) => {
                let total = 0;
                for (let i = 0; i < 12; i++) {
                    total += params.data[i];
                }

                return formatNumberToCurrency(total, 'EUR', '-', 0);
            }
        })
    }

    colDefs.push(...new Array(12).fill().map((e, i) => {
        let d = new Date()
        d.setMonth(i)
        return {
            field: i.toFixed(0), headerName: d.toLocaleString('en-US', {month: 'short'}),
            cellRenderer: currencyFormatter,
            filter: 'agNumberColumnFilter',
            cellStyle: (params) => {
                let result = {}
                if (params.value < 0) {
                    result.color = 'red'
                }
                if (params.value > 0) {
                    result.color = 'green'
                }
                if (params.data.title === 'Total Forecast') {
                    result.backgroundColor = 'lightblue'
                }
                if (params.data.title === 'Forecast vs Actuals') {
                    result.fontStyle = "italic"
                }

                return result
            },
        }
    }))

    let gridData = []
    if (tab !== 'NOI' && tab !== 'Opex') {
        gridData.push({
            title: tab === 'TurnoverRent' ? "Forecast" : "Contracted",
            ...data.contractedList
        })
    }
    if (tab !== 'TurnoverRent' && tab !== 'NOI' && tab !== 'Opex') {
        gridData.push({title: "MLA", ...data.mlasList})
    }
    if (tab !== 'TurnoverRent') {
        gridData.push({title: "Total Forecast", ...data.totalforecastsList})
    }
    gridData.push({title: "Actual", ...data.actualsList})

    const autoSizeStrategy = {
        type: 'fitCellContents',
    };

    return (
        <div
            className="ag-theme-quartz" // applying the grid theme
            style={{height: 350, width: "100%"}} // the grid will fill the size of the parent container
        >
            <AgGridReact
                columnDefs={colDefs}
                rowData={gridData}
                enableRangeSelection={true}
                statusBar={{
                    statusPanels: [
                        {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
                        {
                            statusPanel: 'agAggregationComponent', statusPanelParams: {
                                aggFuncs: ['avg', 'sum']
                            }
                        },
                    ]
                }}
                autoSizeStrategy={autoSizeStrategy}
            />
        </div>
    );
};

export default TableSummary;

TableSummary.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    tab: PropTypes.string
};

TableSummary.defaultProps = {
    data: [],
    loading: false,
    loaded: false,
    tab: ''
};

const TableHeader = () => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.noBorder}/>
                <TableCell className={classes.noBorder}>Jan</TableCell>
                <TableCell className={classes.noBorder}>Feb</TableCell>
                <TableCell className={classes.noBorder}>Mar</TableCell>
                <TableCell className={classes.noBorder}>Apr</TableCell>
                <TableCell className={classes.noBorder}>May</TableCell>
                <TableCell className={classes.noBorder}>Jun</TableCell>
                <TableCell className={classes.noBorder}>Jul</TableCell>
                <TableCell className={classes.noBorder}>Aug</TableCell>
                <TableCell className={classes.noBorder}>Sep</TableCell>
                <TableCell className={classes.noBorder}>Oct</TableCell>
                <TableCell className={classes.noBorder}>Nov</TableCell>
                <TableCell className={classes.noBorder}>Dec</TableCell>
            </TableRow>
        </TableHead>
    );
};
