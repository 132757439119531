import {formatZillions} from 'helpers/utils';

export const config = {
    options: {
        noData: {
            text: "Loading...",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000000",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        },
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#0b7de0', '#c41212'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2]
        },
        title: {
            align: 'left'
        },
        grid: {
            show: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
        },
        yaxis: {
            labels: {
                formatter(value) {
                    return `€${formatZillions(value, '', 0)}`;
                },
                style: {
                    colors: ['#797979']
                }
            },
            forceNiceScale: true
        }
    }
};

// show just one axis when hover