import {metaData} from 'config/config';
import {toast} from 'react-toastify';

import {GetTotalsByTypesRequest} from '../../api/aggregation_grpc_web_pb';
import {GetAllGeoRequest} from '../../api/geo_pb';
import {
    ActualsRequest,
    ChartDataRequest,
    ConditionValue,
    ForecastsRequest,
    InCondition,
    SummaryTableRequest
} from '../../api/report_pb';
import {AggregationService} from '../services/aggregation';
import {GeolocationService} from '../services/geolocation';
import {ReportCashfLowService} from '../services/report';

import * as actionTypes from './actionTypes';

const getHomeDataStart = () => ({
    type: actionTypes.GET_HOMEDATA_START
});

const getHomeDataSucess = (homeData, bundleId) => ({
    type: actionTypes.GET_HOMEDATA_SUCCESS,
    homeData,
    bundleId
});

const getHomeDataFail = (error) => ({
    type: actionTypes.GET_HOMEDATA_FAIL,
    error
});

export const setHomeDataBundle = (bundleId) => ({
    type: actionTypes.SET_HOMEDATA_BUNDLE,
    bundleId
});

export const getHomeData = (portfolioIds, assetTypes, bundleId) => async (dispatch) => {
    dispatch(getHomeDataStart());
    const request = new GetTotalsByTypesRequest();

    request.setPortfolioidsList(portfolioIds ?? []);
    request.setAssettypesList(assetTypes ?? []);

    try {
        const result = await AggregationService.getTotalsByTypes(request, metaData());
        const response = result.toObject();

        dispatch(getHomeDataSucess(response, bundleId));
        return response;
    } catch (error) {
        dispatch(getHomeDataFail(error.message));
        toast.error(error.message);
        throw error.message;
    }
};

const getChartDataStart = () => ({
    type: actionTypes.GET_CHART_DATA_START
});

const getChartDataSuccess = (data) => ({
    type: actionTypes.GET_CHART_DATA_SUCCESS,
    data
});

const getChartDataFail = (error) => ({
    type: actionTypes.GET_CHART_DATA_FAIL,
    error
});

const getGeoDataStart = () => ({
    type: actionTypes.GET_GEO_DATA_START
});

const getGeoDataSuccess = (data) => ({
    type: actionTypes.GET_GEO_DATA_SUCCESS,
    data
});

const getGeoDataFail = (error) => ({
    type: actionTypes.GET_GEO_DATA_FAIL,
    error
});

const getGeoDataPageStart = () => ({
    type: actionTypes.GET_CHART_DATA_PAGE_START
});

const getGeoDataPageSuccess = (data) => ({
    type: actionTypes.GET_CHART_DATA_PAGE_SUCCESS,
    data
});

const getGeoDataPageFail = (error) => ({
    type: actionTypes.GET_CHART_DATA_PAGE_FAIL,
    error
});

export const getBaseRentChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getBaseRentChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getTunoverRentChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getTurnoverChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getOpexChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOpexChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getCapexChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCapexChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getServiceChargeChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getServiceChargeChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getCumulativeBaseRentChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCumulativeBaseRentChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getNoiChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getNoiChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getTenantsChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getTenantsNumberChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getOccupancyChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOccupancyChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getMLAChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getMLAChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getOtherIncomeChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOtherIncomeChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getOtherExpensesChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOtherExpensesChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getLeasingFeeChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getLeasingFeeChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

export const getFitOutChartData = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getFitOutChartData(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });

const getRequest = (appliedFilters, request, period, annualData) => {
    const {AssetID, PortfolioID, Year} = appliedFilters;

    const assetIds = [];
    if (AssetID && AssetID.length > 0) {
        const addFilters = request.addFilters();
        const inCondition = new InCondition();
        addFilters.setField('AssetID');
        AssetID.forEach((id) => {
            const conditionValue = new ConditionValue();
            conditionValue.setStringvalue(id);
            assetIds.push(conditionValue);
        });

        inCondition.setValuesList(assetIds);
        addFilters.setIn(inCondition);
    }

    const portfolioIds = [];
    if (PortfolioID && PortfolioID.length > 0) {
        const addFilters = request.addFilters();
        const inCondition = new InCondition();
        addFilters.setField('PortfolioID');
        PortfolioID.forEach((id) => {
            const conditionValue = new ConditionValue();
            conditionValue.setStringvalue(id);
            portfolioIds.push(conditionValue);
        });

        inCondition.setValuesList(portfolioIds);
        addFilters.setIn(inCondition);
    }

    const years = [];
    if (Year && Year.length > 0) {
        const addFilters = request.addFilters();
        const inCondition = new InCondition();
        addFilters.setField('Year');
        Year.forEach((year) => {
            const conditionValue = new ConditionValue();
            conditionValue.setInt64value(parseInt(year, 10));
            years.push(conditionValue);
        });

        inCondition.setValuesList(years);
        addFilters.setIn(inCondition);
    }

    if (period) {
        request.setPeriod(period === 'month' ? proto.external.ChartDataRequest.Period.MONTH : proto.external.ChartDataRequest.Period.YEAR);
    }
    if (annualData) {
        let data = new proto.external.ChartDataRequest.AnnualData()
        data.setLength(annualData.length)
        data.setYear(annualData.year)
        request.setAnnualdata(data)
    }

    return request;
};

export const getGeoData = (appliedFilters) => async (dispatch) => {
    dispatch(getGeoDataStart());

    const request = getRequest(appliedFilters, new GetAllGeoRequest());

    try {
        const result = await GeolocationService.getAll(request, metaData());
        const response = result.toObject();
        dispatch(getGeoDataSuccess(response.locationsList));
        return Promise.resolve(response);
    } catch (error) {
        dispatch(getGeoDataFail(error.message));
        return Promise.reject(error.message);
    }
};

export const getChartData = (appliedFilters, period, annualData) => async (dispatch) => {
    dispatch(getChartDataStart());

    const request = getRequest(appliedFilters, new ChartDataRequest(), period, annualData);

    try {
        const promises = [
            getBaseRentChartData(request),
            getTunoverRentChartData(request),
            getOpexChartData(request),
            getCapexChartData(request),
            getServiceChargeChartData(request),
            getCumulativeBaseRentChartData(request),
            getNoiChartData(request),
            getTenantsChartData(request),
            getOccupancyChartData(request),
            getMLAChartData(request),
            getOtherIncomeChartData(request),
            getOtherExpensesChartData(request),
            getLeasingFeeChartData(request),
            getFitOutChartData(request)
        ];

        const [
            BaseRent,
            TurnoverRent,
            Opex,
            Capex,
            ServiceCharge,
            CumulativeRent,
            NOI,
            Tenants,
            Occupancy,
            MLA,
            OtherIncome,
            OtherExpenses,
            LeasingFee,
            FitOut
        ] = await Promise.allSettled(promises);

        const result = {
            BaseRent,
            TurnoverRent,
            Opex,
            Capex,
            ServiceCharge,
            CumulativeRent,
            NOI,
            Tenants,
            Occupancy,
            MLA,
            OtherIncome,
            OtherExpenses,
            LeasingFee,
            FitOut
        };

        Object.keys(result).forEach((key) => {
            if (result[key].status === 'rejected') {
                result[key] = {error: true};
            } else {
                result[key] = result[key].value;
            }
        });

        dispatch(getChartDataSuccess(result));
    } catch (error) {
        dispatch(getChartDataFail(error));
    }
};

export const getChartDataPerPage = (page, appliedFilters, period = 'month') => async (dispatch) => {
    dispatch(getGeoDataPageStart());

    const request = getRequest(appliedFilters, new ChartDataRequest(), period);

    const types = {
        BaseRent: () => getBaseRentChartData(request),
        TurnoverRent: () => getTunoverRentChartData(request),
        Opex: () => getOpexChartData(request),
        Capex: () => getCapexChartData(request),
        ServiceCharge: () => getServiceChargeChartData(request),
        CumulativeRent: () => getCumulativeBaseRentChartData(request),
        NOI: () => getNoiChartData(request),
        Tenants: () => getTenantsChartData(request),
        Occupancy: () => getOccupancyChartData(request),
        MLA: () => getMLAChartData(request),
        OtherIncome: () => getOtherIncomeChartData(request),
        OtherExpenses: () => getOtherExpensesChartData(request),
        LeasingFee: () => getLeasingFeeChartData(request),
        FitOut: () => getFitOutChartData(request)
    };

    try {
        const result = await types[page]();
        dispatch(getGeoDataPageSuccess(result));
    } catch (error) {
        dispatch(getGeoDataPageFail(error.message));
    }
};

// Summary table data
const getSummaryTableStart = () => ({
    type: actionTypes.GET_SUMMARY_TABLE_START
});

const getSummaryTableSuccess = (data) => ({
    type: actionTypes.GET_SUMMARY_TABLE_SUCCESS,
    data
});

const getSummaryTableFail = (error) => ({
    type: actionTypes.GET_SUMMARY_TABLE_FAIL,
    error
});

const getBaseRentSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getBaseRentSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getTurnoverRentSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getTurnoverSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getServiceChargeSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getServiceChargeSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getCapexSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCapexSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getOpexSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOpexSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getCumulativeBaseRentSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCumulativeBaseRentSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getNOISummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getNoiSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getLeasingFeeSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getLeasingFeeSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

const getFitOutSummaryTable = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getFitOutSummaryTable(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject());
            }
        });
    });

export const getSummaryTableData = (page, appliedFilters) => async (dispatch) => {
    dispatch(getSummaryTableStart());

    const request = getRequest(appliedFilters, new SummaryTableRequest());

    const types = {
        BaseRent: () => getBaseRentSummaryTable(request),
        TurnoverRent: () => getTurnoverRentSummaryTable(request),
        ServiceCharge: () => getServiceChargeSummaryTable(request),
        Capex: () => getCapexSummaryTable(request),
        Opex: () => getOpexSummaryTable(request),
        CumulativeRent: () => getCumulativeBaseRentSummaryTable(request),
        NOI: () => getNOISummaryTable(request),
        LeasingFee: () => getLeasingFeeSummaryTable(request),
        FitOut: () => getFitOutSummaryTable(request)
    };

    try {
        const result = await types[page]();
        dispatch(getSummaryTableSuccess(result));
    } catch (error) {
        dispatch(getSummaryTableFail(error));
    }
};

// Tenants forecasts
const getTenantsForecastsStart = () => ({
    type: actionTypes.GET_TENANTS_FORECAST_START
});

const getTenantsForecastsSuccess = (data) => ({
    type: actionTypes.GET_TENANTS_FORECAST_SUCCESS,
    data
});

const getTenantsForecastsFail = (error) => ({
    type: actionTypes.GET_TENANTS_FORECAST_FAIL,
    error
});

const getBaseRentTenantsForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getBaseRentForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getTurnoverRentTenantsForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getTurnoverForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getServiceChargeTenantsForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getServiceChargeForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getCapexTenantsForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCapexForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getOpexTenantsForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOpexForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getCumulativeBaseRentForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCumulativeBaseRentForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getNoiForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getNoiForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getLeasingFeeForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getLeasingFeeForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

const getFitOutForecasts = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getFitOutForecasts(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().forecastsList);
            }
        });
    });

export const getTenantsForecasts = (page, appliedFilters) => async (dispatch) => {
    dispatch(getTenantsForecastsStart());

    const request = getRequest(appliedFilters, new ForecastsRequest());

    const types = {
        BaseRent: () => getBaseRentTenantsForecasts(request),
        TurnoverRent: () => getTurnoverRentTenantsForecasts(request),
        ServiceCharge: () => getServiceChargeTenantsForecasts(request),
        Capex: () => getCapexTenantsForecasts(request),
        Opex: () => getOpexTenantsForecasts(request),
        CumulativeRent: () => getCumulativeBaseRentForecasts(request),
        NOI: () => getNoiForecasts(request),
        LeasingFee: () => getLeasingFeeForecasts(request),
        FitOut: () => getFitOutForecasts(request)
    };

    try {
        const result = await types[page]();

        dispatch(getTenantsForecastsSuccess(result));
    } catch (error) {
        dispatch(getTenantsForecastsFail(error));
    }
};

// Tenants actuals
const getTenantsActualsStart = () => ({
    type: actionTypes.GET_TENANTS_ACTUALS_START
});

const getTenantsActualsSuccess = (data) => ({
    type: actionTypes.GET_TENANTS_ACTUALS_SUCCESS,
    data
});

const getTenantsActualsFail = (error) => ({
    type: actionTypes.GET_TENANTS_ACTUALS_FAIL,
    error
});

const getBaseRentTenantsActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getBaseRentActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getTurnoverRentTenantsActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getTurnoverActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getServiceChargeTenantsActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getServiceChargeActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getCapexTenantsActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCapexActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getOpexTenantsActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getOpexActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getCumulativeBaseRentActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getCumulativeBaseRentActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getNoiActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getNoiActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getLeasingFeeActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getLeasingFeeActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

const getFitOutActuals = (request) =>
    new Promise((resolve, reject) => {
        ReportCashfLowService.getFitOutActuals(request, metaData(), (err, resp) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(resp.toObject().actualsList);
            }
        });
    });

export const getTenantsActuals = (page, appliedFilters) => async (dispatch) => {
    dispatch(getTenantsActualsStart());

    const request = getRequest(appliedFilters, new ActualsRequest());

    const types = {
        BaseRent: () => getBaseRentTenantsActuals(request),
        TurnoverRent: () => getTurnoverRentTenantsActuals(request),
        ServiceCharge: () => getServiceChargeTenantsActuals(request),
        Capex: () => getCapexTenantsActuals(request),
        Opex: () => getOpexTenantsActuals(request),
        CumulativeRent: () => getCumulativeBaseRentActuals(request),
        NOI: () => getNoiActuals(request),
        LeasingFee: () => getLeasingFeeActuals(request),
        FitOut: () => getFitOutActuals(request)
    };

    try {
        const result = await types[page]();
        dispatch(getTenantsActualsSuccess(result));
    } catch (error) {
        dispatch(getTenantsActualsFail(error));
    }
};

export const setYearFilter = (year) => ({
    type: actionTypes.SET_YEAR_FILTER,
    year
});

export const getFilterYears = () => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_HOMEDATAYEARS_START
    });

    try {
        const currentYear = new Date().getFullYear();
        const options = [];
        const yearsBack = 3;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < yearsBack; i++) {
            options.push({
                id: `${currentYear - i}`,
                label: `${currentYear - i}`,
                name: `${currentYear - i}`
            });
        }

        dispatch({
            type: actionTypes.GET_HOMEDATAYEARS_SUCCESS,
            data: options
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_HOMEDATAYEARS_FAIL,
            error
        });
    }
};

export const resetGeoData = () => ({
    type: actionTypes.RESET_GEO_DATA_STATE
});
