/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState} from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {useLocation} from 'react-router-dom';
import UserHasAccess from 'hooks/UserHasAccess';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './styles';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import * as actions from "../../store/actions";
import useLocalStorage from "../../hooks/useLocalStorage";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {Logout} from "@mui/icons-material";
import SideBar from "./Sidebar";

const navLinks = [
    {path: '/', name: 'Home'},
    {path: '/Portfolios', name: 'Portfolios'},
    {path: '/Assets', name: 'Assets'},
    {path: '/Tenants', name: 'Tenants'},
];

function ToolbarComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {open} = useSelector((state) => state.alerts);
    const userNotifications = useSelector((state) => state.alerts.alerts.length);
    const role = useSelector((state) => state.user.userPermissions.roletitle);
    const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
    const assetAccess = UserHasAccess('asset', 'view', userPermissions);
    const [hover, setHover] = useState(false);
    const location = useLocation();
    const {removeValue} = useLocalStorage('token', null);

    const handleSignOut = useCallback(() => {
        removeValue();
        dispatch(actions.signOut());
    }, []);

    return (
        <AppBar position="static" style={
            {
                backgroundColor: '#1B2740',
                position: 'fixed',
                zIndex: 1000,
                paddingLeft: 20
            }
        }>
            {assetAccess && <SideBar open={open} setOpen={() => dispatch(actions.toggleAlertMenu())}/>}
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
                    {navLinks.map((item) => (
                        <Button key={item.name} sx={{
                            color: '#fff',
                            // Highlight if the current path matches the link's path
                            backgroundColor: location.pathname === item.path ? '#33415c' : 'transparent',
                            margin: 1,
                            '&:hover': {
                                backgroundColor: '#33415c' // Keep hover effect
                            }
                        }} to={item.path}>
                            {item.name}
                        </Button>
                    ))}
                </Box>
                <Box sx={{flexGrow: 0, margin: 1}}>
                    {role && (
                        <IconButton
                            aria-label="show 14 new notifications"
                            color="inherit"
                            onClick={() => dispatch(actions.toggleAlertMenu())}
                            classes={{root: classes.iconBtn}}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            disabled={!userNotifications}
                            size="large"

                        >
                            <Badge badgeContent={userNotifications} classes={{badge: classes.customBadge}}>
                                {userNotifications > 0 && hover ? (
                                    <NotificationsIcon/>
                                ) : (
                                    <NotificationsNoneIcon
                                        style={{
                                            color: userNotifications > 0 ? '#FFF' : '#999'
                                        }}
                                    />
                                )}
                            </Badge>
                        </IconButton>
                    )}
                    <IconButton onClick={handleSignOut} aria-label="logout" style={{color: '#fff'}}>
                        <Logout/>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default React.memo(ToolbarComponent);
