/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useRef, useState} from 'react';

import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions';
import useSupercluster from 'use-supercluster';

import SimpleMarker from './SimpleMarker';

const clusterStyle = {
    color: '#fff',
    border: '1px solid white',
    background: '#2099ba',
    borderRadius: '50%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const Marker = ({children}) => children;

const MapComponent = ({
                          assets,
                          defaulCenter,
                          defaultZoom,
                          mapType,
                          center,
                          zoom: mapZoom,
                          detailPage
                      }) => {
    const {data: coordinates, loaded: geoDataLoaded} = useSelector((state) => state.home.geoData);
    const [coords, setCoordinates] = useState(coordinates);
    const [maps, setMaps] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);
    const [show, setShow] = useState({});
    const dispatch = useDispatch();
    const mapRef = useRef();

    const getRentPerAsset = async () => {
        const rents = await dispatch(actions.getTotalRentByAssetAndYear(coordinates.map((cor) => cor.assetid), new Date().getFullYear()));

        return coordinates.map((cor, i) => {
                const rent = rents.find((r) => r.assetid === cor.assetid)?.rent || 0;

                return {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [parseFloat(cor.longitude), parseFloat(cor.latitude)]
                    },
                    properties: {
                        cluster: false,
                        id: i,
                        asset: {
                            ...assets.find((asset) => asset.id === cor.assetid),
                            rent
                        }
                    }
                };
            })
    }

    useEffect(() => {
        if (!coordinates || coordinates.length === 0) {
            return
        }

        getRentPerAsset().then((newCoords) => {
            setCoordinates(newCoords);
        });
    }, [coordinates]);

    useEffect(() => {
        if (!assets || assets.length === 0) {
            return;
        }

        dispatch(actions.getGeoData({
            AssetID: assets.map((asset) => asset.id),
        }));
    }, [assets]);

    const points = coords || [];
    const {clusters, supercluster} = useSupercluster({
        points,
        bounds,
        zoom,
        options: {radius: 75, maxZoom: 20}
    });

    useEffect(() => {
        setCoordinates(clusters);
    }, [coordinates]);

    const onMouseEnter = (index) => setShow({[index]: true});
    const onMouseLeave = (index) => setShow({[index]: false});

    return (
        <GoogleMapReact
            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
            onGoogleApiLoaded={({map: MapObj, maps: MapsObj}) => {
                mapRef.current = MapObj;
                setMaps(MapsObj);
            }}
            onChange={({zoom: clusterZoom, bounds: mapBounds}) => {
                setZoom(clusterZoom);
                setBounds([mapBounds.nw.lng, mapBounds.se.lat, mapBounds.se.lng, mapBounds.nw.lat]);
            }}
            options={{
                zoomControlOptions: {
                    position: maps?.ControlPosition.RIGHT_BOTTOM,
                    style: maps?.ZoomControlStyle.SMALL
                },
                mapTypeControlOptions: {
                    position: maps?.ControlPosition.TOP_LEFT
                },
                mapTypeControl: true,
                mapTypeId: mapType
            }}
            defaultCenter={defaulCenter}
            center={coordinates && coordinates.length === 1 ? [coordinates[0].latitude, coordinates[0].longitude] : center}
            defaultZoom={defaultZoom}
            zoom={mapZoom}
            shouldUnregisterMapOnUnmount
            onChildMouseLeave={onMouseLeave}
            onChildMouseEnter={onMouseEnter}
        >
            {!detailPage && clusters &&
                clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {cluster: isCluster, point_count: pointCount, asset, id} = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker key={id} lat={latitude} lng={longitude}>
                                <div
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(cluster.id),
                                            20
                                        );
                                        mapRef.current.setZoom(expansionZoom);
                                        mapRef.current.panTo({lat: latitude, lng: longitude});
                                    }}
                                    style={{
                                        ...clusterStyle,
                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                        height: `${10 + (pointCount / points.length) * 20}px`
                                    }}
                                >
                                    {pointCount}
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <Marker key={id} lat={latitude} lng={longitude}>
                            <SimpleMarker place={asset} show={(!isCluster && show[id]) || false}/>
                        </Marker>
                    );
                })}
            {detailPage && coordinates &&
                coordinates.map((cord) => (
                    <Marker key={cord.id} lat={cord.latitude} lng={cord.longitude}>
                        <SimpleMarker/>
                    </Marker>
                ))}
        </GoogleMapReact>
    );
};

export default MapComponent;

MapComponent.defaultProps = {
    assets: [],
    defaulCenter: [49.93, 49.33],
    center: [49.93, 49.33],
    defaultZoom: 3,
    zoom: 3,
    mapType: 'terrain',
    detailPage: false
};

MapComponent.propTypes = {
    assets: PropTypes.arrayOf(PropTypes.object),
    defaulCenter: PropTypes.arrayOf(PropTypes.number),
    defaultZoom: PropTypes.number,
    zoom: PropTypes.number,
    mapType: PropTypes.string,
    center: PropTypes.array,
    detailPage: PropTypes.bool
};
