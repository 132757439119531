/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, Suspense, lazy } from 'react';

import Box from '@mui/material/Box';
import Spinner from 'components/Spinner';
import { Switch, Route } from 'react-router-dom';

import SideMenu from './SideMenu';
import useStyles from './styles';
import AssetChartDetails from "./AssetChartDetails";

const Actuals = lazy(() => import('./Actuals'));
const Turnover = lazy(() => import('./Turnover'));
const Documents = lazy(() => import('./Documents'));
const Tenants = lazy(() => import('./Tenants'));
const Details = lazy(() => import('./Details'));
const CashManager = lazy(() => import('Workflows/Shared/CashManager/AssetCashManager'));
const OpexManager = lazy(() => import('Workflows/Property/OpexManager'));
const FacilityManager = lazy(() => import('Workflows/Property/FacilityManager'));
const CostManager = lazy(() => import('Workflows/Asset/AssetCostManager'));
const LegalTracker = lazy(() => import('Workflows/Asset/LegalTracker'));
const Dashboard = lazy(() => import('Workflows/Asset/Reports/Dashboard/Dashboard'));
const Valuations = lazy(() => import('Workflows/Asset/Valuations'));
const AdvancedReports = lazy(() => import('./AdvancedReports'));
const AssetCostManagerFlow = lazy(() =>
  import('Workflows/Asset/AssetCostManager/AssetCostManagerFlow')
);
const AssetLegalTrackerDetails = lazy(() =>
  import('Workflows/Asset/LegalTracker/LegalTrackerDetails')
);
const ValuationsDetails = lazy(() => import('Workflows/Asset/Valuations/ValuationsDetails'));
const FacilityManagerDetails = lazy(() =>
  import('Workflows/Property/FacilityManager/FacilityDetails')
);
const OpexManagerFlow = lazy(() => import('Workflows/Property/OpexManager/OpexManagerFlow'));

const routes = [
  { path: '/Asset/:id/Details', component: Details },
  { path: '/Asset/:id/Tenants', component: Tenants },
  { path: '/Asset/:id/OpexManager', component: OpexManager },
  { path: '/Asset/:id/FacilityManager', component: FacilityManager },
  { path: '/Asset/:id/Turnover', component: Turnover },
  { path: '/Asset/:id/Actuals', component: Actuals },
  { path: '/Asset/:id/Valuations', component: Valuations },
  { path: '/Asset/:id/CostManager', component: CostManager },
  { path: '/Asset/:id/CashManager', component: CashManager },
  { path: '/Asset/:id/LegalTracker', component: LegalTracker },
  { path: '/Asset/:id/Reports', component: Dashboard },
  { path: '/Asset/:id/AdvancedReporting', component: AdvancedReports },
  { path: '/Asset/:id/Documents', component: Documents },
  { path: '/Asset/:id/OpexManager/:index', component: OpexManagerFlow },
  { path: '/Asset/:id/FacilityManager/:index', component: FacilityManagerDetails },
  { path: '/Asset/:id/LegalTracker/:index', component: AssetLegalTrackerDetails },
  { path: '/Asset/:id/CostManager/:index', component: AssetCostManagerFlow },
  { path: '/Asset/:id/Valuations/:index', component: ValuationsDetails },
  { path: '/Asset/:id/:tab(BaseRent|TurnoverRent|ServiceCharge|OtherIncome|CumulativeRent|Opex|Capex|OtherExpenses|NOI|LeasingFee|FitOut)/:index?', component: AssetChartDetails }
];

const AssetDetails = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box maxWidth="210px" minWidth="210px">
        <SideMenu />
      </Box>
      <Box width="100%" className={classes.scrollable}>
        <Box>
          <Suspense fallback={<Spinner mini />}>
            <Switch>
              {routes.map((route) => (
                <Route exact path={route.path} key={route.path}>
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetDetails;
