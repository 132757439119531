/* eslint-disable no-new */
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Spinner from 'components/Spinner';
import useLocalStorage from 'hooks/useLocalStorage';
import useUrlParams from 'hooks/useUrlParams';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions';

import CustomAutocomplete from './CustomAutocomplete';
import InfoPanel from './InfoPanel';
import Map from './Map/Map';
import useStyles from './styles';
import Tasks from './Tasks';
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material/styles";
import KPIs from "./KPIs";

const FiltersWrapper = styled(Box)(({theme}) => ({
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4,
    margin: 0
}));

const HomeScreen = () => {
    const classes = useStyles();

    const [year, setYear] = useState(new Date().getFullYear());
    const {
        homeData,
        loaded: homeDataLoaded,
    } = useSelector((state) => state.home);
    const {portfolios} = useSelector((state) => state.portfolios);
    const {data: assets} = useSelector((state) => state.assets.assets);
    const {portfolioIds, setPortfolios, assetIds, setAssetIds} = useUrlParams();
    const [period, setPeriod] = React.useState('month');
    const [targetAssetIds, setTargetAssetIds] = React.useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getHomeData());
        dispatch(actions.getAlertsCount());
    }, []);

    useEffect(() => {
        if (!portfolioIds) {
            return;
        }

        dispatch(actions.getAssets(portfolioIds))
    }, [portfolioIds]);

    useEffect(() => {
        if (!assets) {
            return;
        }

        setTargetAssetIds(assets.map((item) => item.id));
    }, [assets]);

    useEffect(() => {
        setTargetAssetIds(assetIds);
    }, [assetIds]);

    useEffect(() => {
        dispatch(actions.getAlertsList([], targetAssetIds));
    }, [targetAssetIds]);

    if (!homeDataLoaded) {
        return <Spinner/>;
    }

    return (
        <Container maxWidth="xl" className={classes.paddingX}>
            <InfoPanel data={homeData}/>
            <Grid container spacing={4} alignItems="stretch">
                <Grid item xs={12} sm={12} md={8}>
                    <Box className={classes.mapBox} sx={{height: '400px'}}>
                        <Map
                            assets={assets}
                            defaulCenter={[50.07, 14.43]}
                            defaultZoom={3}
                            mapType="hybrid"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Tasks/>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
            <FiltersWrapper>
                <CustomAutocomplete
                    items={portfolios}
                    updateUrlParams={setPortfolios}
                    urlParams={portfolioIds}
                    label="Portfolios"
                    placeholder="Portfolios"
                    disabled={!!assetIds.length}
                />
                <CustomAutocomplete
                    items={assets}
                    updateUrlParams={setAssetIds}
                    urlParams={assetIds}
                    label="Assets"
                    placeholder="Assets"
                />
                <FormControl>
                    <InputLabel>Year</InputLabel>
                    <Select
                        label="Year"
                        keyword="label"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        sx={{backgroundColor: 'white'}}
                        IconComponent={null}
                    >
                        {new Array(3).fill().map((_, i) => {
                            return new Date().getFullYear() - i
                        }).map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <ToggleButtonGroup
                    color="primary"
                    value={period}
                    exclusive
                    onChange={(_, value) => setPeriod(value)}
                    aria-label="Platform"
                >
                    <ToggleButton value="month">Monthly</ToggleButton>
                    <ToggleButton value="year">Annual</ToggleButton>
                </ToggleButtonGroup>
            </FiltersWrapper>
            <KPIs
                portfolioIds={portfolioIds}
                assetIds={assetIds}
                period={period}
                year={year}
            />
            <Divider sx={{my: 4}}/>
        </Container>
    );
};

export default HomeScreen;
